







import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import store from '../../store';

@Component
export default class Breadcrumb extends Vue {
  @Prop({default: () => []}) menuItems!: string[]
}
